import type { components } from '@writercolab/network';

/**
 * An incredibly basic in-memory client-side cache.
 */
const cache = new Map<string, any>();

type CacheKeys = {
  permissions: components['schemas']['auth_dto_UserPermissionsResponse'];
  userProfile: components['schemas']['com_qordoba_user_dto_UserProfileResponse'];
};

export function setCache<T extends keyof CacheKeys>(
  key: T,
  value: CacheKeys[T],
) {
  cache.set(key, value);
}

export function getCache<T extends keyof CacheKeys>(
  key: T,
): CacheKeys[T] | undefined {
  return cache.get(key);
}

export function hasCache<T extends keyof CacheKeys>(key: T) {
  return cache.has(key);
}

export function clearCache() {
  cache.clear();
}
