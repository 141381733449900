import { ssoRoute, TSignupPlan, googleSsoUrl } from '../../constants';
import { QueryParams, SignupTypes } from '../types';

import { baseUrl } from './fetcher';

export const buildSamlRedirectURL = (
  email: string,
  redirectTo?: string | null,
): string => {
  const samlRedirectUrl = `/redirect-auth?${new URLSearchParams({
    [QueryParams.enum.signupType]: SignupTypes.enum.saml,
    login: 'true',
    [QueryParams.enum.login_source]: encodeURIComponent('SAML SSO'),
    ...(redirectTo && { [QueryParams.enum.redirectTo]: redirectTo }),
  }).toString()}`;

  const samlFailUrl = `${baseUrl}/saml`;

  const params = new URLSearchParams({
    [QueryParams.enum.email]: email.toString(),
    [QueryParams.enum.redirectTo]: samlRedirectUrl,
    [QueryParams.enum.failUrl]: samlFailUrl,
  });

  return `${baseUrl}/${ssoRoute}?${params.toString()}`;
};

const buildGoogleAuthURL = (
  registrationPlan: typeof TSignupPlan.type = TSignupPlan.enum.ai_studio_payg,
  appRedirectURL: string = '/',
  additionalParams?: Record<string, string>,
  redirectTo?: string,
) => {
  let googleAuthRoot = `${baseUrl}/${googleSsoUrl}`;

  const queryString = new URLSearchParams({
    registrationPlan,
    redirectUrl: `${baseUrl}${redirectTo || appRedirectURL}`,
    signupType: SignupTypes.enum.google,
  });

  if (additionalParams) {
    Object.keys(additionalParams).forEach((key) =>
      queryString.append(key, additionalParams[key]),
    );
  }

  googleAuthRoot += `?${queryString.toString()}`;

  return googleAuthRoot;
};

export const handleGoogleAuth = async (
  redirectTo?: string,
  register?: boolean,
) => {
  const opts: Record<string, string> = {};

  if (register) {
    opts.register = 'true';
  }

  return window.open(
    buildGoogleAuthURL(TSignupPlan.enum.ai_studio_payg, '/', opts, redirectTo),
    '_self',
  );
};

export const isDomainWhitelisted = (
  domains: string[],
  email: string,
): boolean => {
  const emailDomain = email.substring(email.lastIndexOf('@') + 1);
  const whitelistedDomains = domains || [];

  return whitelistedDomains.some(
    (domain: string) => domain.toLowerCase() === emailDomain,
  );
};
