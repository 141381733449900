/* v8 ignore next 34 */
export const Logo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="99"
    height="33"
    viewBox="0 0 99 33"
    fill="none"
    {...props}
  >
    <path
      d="M3.43507 32.4968L0 0H6.27273L8.06494 20.5044L9.85715 0H15.8312L17.5487 20.5044L19.2663 0H25.6137L22.1039 32.4968H14.4124L12.8068 16.8348L11.276 32.4968H3.43507Z"
      fill="black"
    />
    <path
      d="M27.4346 0H37.2918C38.8599 0 40.0672 0.365699 40.9135 1.0971C41.7847 1.80327 42.3697 2.8121 42.6684 4.12357C42.9671 5.40982 43.1164 7.06177 43.1164 9.07942C43.1164 10.9205 42.88 12.3581 42.407 13.3921C41.9341 14.4262 41.1126 15.145 39.9427 15.5485C40.9135 15.7503 41.6105 16.2421 42.0336 17.0239C42.4817 17.8057 42.7057 18.865 42.7057 20.2017L42.631 32.4968H36.3583V19.7856C36.3583 18.8776 36.1841 18.2976 35.8356 18.0453C35.4871 17.7931 34.8524 17.667 33.9314 17.667V32.4968H27.4346V0ZM35.4995 12.0302C36.3956 12.0302 36.8437 11.0466 36.8437 9.07942C36.8437 8.22192 36.8064 7.57879 36.7317 7.15004C36.657 6.72129 36.5201 6.43126 36.321 6.27993C36.1218 6.10339 35.8356 6.01511 35.4622 6.01511H33.9687V12.0302H35.4995Z"
      fill="black"
    />
    <path d="M44.9296 32.4968V0H51.1277V32.4968H44.9296Z" fill="black" />
    <path
      d="M56.4507 32.4968V6.2421H52.6422V0H66.6812V6.2421H62.8727V32.4968H56.4507Z"
      fill="black"
    />
    <path
      d="M68.4915 32.4968V0H81.3356V6.27993H75.0256V12.5977H81.0743V18.7263H75.0256V26.179H81.7464V32.4968H68.4915Z"
      fill="black"
    />
    <path
      d="M83.3182 0H93.1753C94.7435 0 95.9507 0.365699 96.7971 1.0971C97.6683 1.80327 98.2532 2.8121 98.5519 4.12357C98.8506 5.40982 99 7.06177 99 9.07942C99 10.9205 98.7635 12.3581 98.2906 13.3921C97.8176 14.4262 96.9962 15.145 95.8263 15.5485C96.7971 15.7503 97.494 16.2421 97.9172 17.0239C98.3652 17.8057 98.5893 18.865 98.5893 20.2017L98.5146 32.4968H92.2419V19.7856C92.2419 18.8776 92.0676 18.2976 91.7191 18.0453C91.3707 17.7931 90.7359 17.667 89.8149 17.667V32.4968H83.3182V0ZM91.3831 12.0302C92.2792 12.0302 92.7273 11.0466 92.7273 9.07942C92.7273 8.22192 92.6899 7.57879 92.6152 7.15004C92.5406 6.72129 92.4037 6.43126 92.2045 6.27993C92.0054 6.10339 91.7191 6.01511 91.3458 6.01511H89.8522V12.0302H91.3831Z"
      fill="black"
    />
  </svg>
);
