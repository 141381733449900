import { Routes } from '~/constants';
import { fetcher } from '../fetcher';

export function isRootRoute(pathname: string) {
  return pathname === '/';
}

export function isCurrentStep(pathname: string, step: string) {
  return pathname.startsWith(step);
}

/**
 * Checks if the user is authenticated. This will always
 * be a clean call to the permissions service since we need fresh
 * data in every case.
 */
export const isAuthenticated = async (): Promise<boolean> => {
  const { error } = await fetcher.GET('/api/auth/permission', {
    cache: 'no-store',
  });

  return !error;
};

/**
 * Public routes are routes that are accessible to everyone
 * and do not require authentication.
 */
const publicRoutes = [
  Routes.Login,
  Routes.Register,
  Routes.ResetPassword,
  Routes.AppAccess,
  Routes.Saml,
];

export function isPublicRoute(pathname: string) {
  return publicRoutes.some((route) => pathname.startsWith(route));
}

/**
 * Protected routes are routes that don't necessarily require
 * authentication but will require local storage items to
 * be set so we know where the user is in the onboarding flow.
 */
const protectedRoutes = [Routes.Verify];

export function isProtectedRoute(pathname: string) {
  return protectedRoutes.some((route) => pathname.startsWith(route));
}

/**
 * Private routes are routes that require authentication.
 */
const privateRoutes = [
  Routes.JoinOrg,
  Routes.JoinCreateOrg,
  Routes.NewOrg,
  Routes.PendingApproval,
  /onboarding\/.*/,
];

export function isPrivateRoute(pathname: string) {
  return privateRoutes.some((route) => {
    if (typeof route === 'string') {
      return pathname.startsWith(route);
    }

    return route.test(pathname);
  });
}

export function isKnownRoute(step: string) {
  return Object.values(Routes).includes(step as any);
}
