import { TAnalyticsActivityTypeBuilder } from '@writercolab/types';
import type { TSubscriptionType } from '~/@types/types';

export const AnalyticsEvents = {
  signUpButtonClicked: 'Sign Up Button Clicked',
  joinExistingOrgClicked: 'Join Existing Org Clicked',
  createANewOrgClicked: 'Create A New Org Clicked',
  signInButtonClicked: 'Sign In Button Clicked',
  signInErrored: 'Sign In Errored',
  welcomeScreenProductSelected: 'Welcome Screen Product Selected',
  questionnaireOptionSelected: 'Questionnaire Option Selected',
  questionnaireOptionUnselected: 'Questionnaire Option Unselected',
  continueOnScreenQuestionnaireButtonClicked:
    'Continue On Screen Questionnaire Button Clicked',
  questionnaireCompleted: 'Onboarding Questionnaire Completed',
  viewedAppAccess: 'Viewed App Access Page',
} as const;

export type TAnalyticEventParams = {
  [AnalyticsEvents.signUpButtonClicked]: TAnalyticsParamsProps & {
    method: 'Password' | 'Google';
  };
  [AnalyticsEvents.joinExistingOrgClicked]: TAnalyticsParamsProps & {
    type: 'Request' | 'Join';
    user_id?: string | null;
  };
  [AnalyticsEvents.createANewOrgClicked]: TAnalyticsParamsProps & {
    type: 'Create';
    user_id?: string | null;
  };
  [AnalyticsEvents.signInButtonClicked]: TAnalyticsParamsProps & {
    type: 'Password' | 'Google' | 'SAML';
  };
  [AnalyticsEvents.signInErrored]: TAnalyticsParamsProps & {
    error: string;
  };
  [AnalyticsEvents.welcomeScreenProductSelected]: TAnalyticsParamsProps & {
    product: 'Business Workspace' | 'AI Studio';
  };
  [AnalyticsEvents.questionnaireOptionSelected]: TAnalyticsParamsProps &
    (
      | {
          product?: 'Business Workspace' | 'AI Studio';
          user_id: string | null;
        }
      | Record<string, string>
    );
  [AnalyticsEvents.questionnaireOptionUnselected]: TAnalyticsParamsProps &
    (
      | {
          product?: 'Business Workspace' | 'AI Studio';
          user_id: string | null;
        }
      | Record<string, string>
    );
  [AnalyticsEvents.continueOnScreenQuestionnaireButtonClicked]: TAnalyticsParamsProps &
    (
      | {
          product?: 'Business Workspace' | 'AI Studio' | null;
          user_id: string | null;
        }
      | Record<string, string>
    );
  [AnalyticsEvents.questionnaireCompleted]: TAnalyticsParamsProps & {
    product?: 'Business Workspace' | 'AI Studio';
    experience?: string;
    user_id: string | null;
    industry?: string;
    function?: string;
    seniority?: string;
    company_size?: string;
    company_name?: string;
  };
  [AnalyticsEvents.viewedAppAccess]: {
    application_id: string;
    app_name: string;
  };
};

export type TAnalyticsParams = TAnalyticsActivityTypeBuilder<
  typeof AnalyticsEvents,
  TAnalyticEventParams
>;

export type TAnalyticsParamsProps = TDefaultAnalyticEventParams &
  TDefaultEventProperties;

export type TDefaultAnalyticEventParams = {
  organization_id: number | null;
  subscription_type: TSubscriptionType | null;
  utm: {
    source: string | null;
    medium: string | null;
    campaign?: string | null;
    content?: string | null;
    term?: string | null;
    referrer?: string | null;
  };
};

export type TDefaultEventProperties = {
  category: 'Sign Up' | 'Onboarding';
  sub_category: 'Sign Up' | 'Sign In' | 'Onboarding';
  action_type: 'Clicked' | 'Selected' | 'Unselected' | 'Errored' | 'Completed';
  page: string;
};
