import * as ReactDOM from 'react-dom/client';
import type { LoaderFunctionArgs } from 'react-router';
import { createBrowserRouter, redirect } from 'react-router';
import { RouterProvider } from 'react-router/dom';
import '@writercolab/fe.wds/styles';
import { Routes } from './constants';
import App, { loader as rootLoader } from './app/app';
import { getItem, LocalStorageKey } from './app/utils/localStorage';
import LoginPage, {
  action as loginAction,
  loader as loginLoader,
} from './app/pages/login/route';
import SamlPage, {
  action as samlAction,
  loader as samlLoader,
} from './app/pages/saml/route';

import { NotFound } from './app/components/molecules/404/404';
import { getQuestionnaireData } from './app/utils/questionnaire';
import { getUserDetails } from './app/utils/network';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    caseSensitive: true,
    errorElement: <NotFound />,
    loader: rootLoader,
    children: [
      {
        path: Routes.Login,
        element: <LoginPage />,
        action: loginAction,
        loader: loginLoader,
      },
      {
        path: Routes.ResetPassword,
        lazy: () => import('./app/pages/forgot-password/route'),
      },
      {
        path: Routes.Register,
        lazy: () => import('./app/pages/register/route'),
      },
      {
        path: Routes.Verify,
        lazy: () => import('./app/pages/verify/route'),
      },
      {
        path: Routes.Saml,
        element: <SamlPage />,
        action: samlAction,
        loader: samlLoader,
      },
      {
        path: Routes.JoinOrg,
        lazy: () => import('./app/pages/discover/route'),
      },
      {
        path: Routes.JoinCreateOrg,
        lazy: () => import('./app/pages/join-create-org/route'),
      },
      {
        path: Routes.NewOrg,
        lazy: () => import('./app/pages/new-org/route'),
      },
      {
        path: Routes.PendingApproval,
        lazy: () => import('./app/pages/approval-pending/route'),
      },
      {
        path: Routes.OnboardingWelcome,
        lazy: () => import('./app/pages/welcome/route'),
      },
      {
        id: Routes.Onboarding,
        path: Routes.Onboarding,
        loader: async ({ request }: LoaderFunctionArgs) => {
          if (
            request.url.match(new RegExp(`${Routes.Onboarding}/?(?:\\?.*)?$`))
          ) {
            const searchParams = new URL(request.url).search;

            return redirect(`${Routes.OnboardingWelcome}${searchParams}`, {
              status: 301,
            });
          }

          const isQuestionRoute = request.url.includes(
            Routes.OnboardingQuestion,
          );

          const orgId = getItem(LocalStorageKey.OnboardingOrgId);
          const data = await getQuestionnaireData(
            orgId,
            isQuestionRoute ? 'WriterApp' : 'AiStudio',
          );
          const user = await getUserDetails();

          return { ...data, userId: user?.id };
        },
        children: [
          {
            path: Routes.OnboardingAIStudioAboutExperience,
            lazy: () => import('./app/pages/about-experience/route'),
          },
          {
            path: Routes.OnboardingAIStudioAboutPurpose,
            lazy: () => import('./app/pages/about-purpose/route'),
          },
          {
            path: Routes.OnboardingAIStudioAboutIdea,
            lazy: () => import('./app/pages/about-idea/route'),
          },
          {
            path: Routes.OnboardingAIStudioAboutUseCase,
            lazy: () => import('./app/pages/about-use-case/route'),
          },
          {
            path: Routes.OnboardingAIStudioAboutUseCompanyDetails,
            lazy: () => import('./app/pages/about-company-details/route'),
          },
          {
            path: Routes.OnboardingQuestionIndustry,
            lazy: () => import('./app/pages/question-industry/route'),
          },
          {
            path: Routes.OnboardingQuestionRole,
            lazy: () => import('./app/pages/question-role/route'),
          },
          {
            path: Routes.OnboardingQuestionPosition,
            lazy: () => import('./app/pages/question-position/route'),
          },
        ],
      },
      {
        id: Routes.OnboardingWriterApp,
        path: Routes.OnboardingWriterApp,
        loader: async ({ request }: LoaderFunctionArgs) => {
          if (request.url.includes(Routes.OnboardingWriterApp)) {
            const orgId = getItem(LocalStorageKey.OnboardingOrgId);
            const data = await getQuestionnaireData(orgId, 'WriterApp');
            const user = await getUserDetails();

            return { ...data, userId: user?.id };
          }

          return null;
        },
        lazy: () => import('./app/pages/about-writer-app/route'),
      },
    ],
  },
  {
    path: Routes.AppAccess,
    lazy: () => import('./app/pages/app-access/route'),
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <RouterProvider router={router} />,
);
