import { type ActionFunctionArgs } from 'react-router';
import { APIError } from '~/app/errors/APIError';
import { ChallengeRequiredError } from '~/app/errors/ChallengeRequiredError';
import { FieldValidationError } from '~/app/errors/FieldValidationError';
import { trackUserLogin } from '~/app/utils/analytics';
import { baseUrl, fetcher } from '~/app/utils/fetcher';
import { ErrorMessages } from '~/app/utils/messages';
import { isMFARequired } from '~/app/utils/mfa';
import { createErrorResponse, createResponse } from '~/app/utils/new/response';
import { createReCaptchaToken, CaptchaAction } from '~/app/utils/reCAPTCHA';
import { hardRedirect, replace } from '~/app/utils/routerUtils';
import { buildSamlRedirectURL } from '~/app/utils/saml';
import { Routes } from '~/constants';
import { checkRegistration } from './helpers';
import {
  getResponseErrorMessage,
  isInvalidCredential,
} from '~/app/utils/errors';
import type { AsyncActionWithoutResponse } from '~/@types/types';
import { LoginError } from '~/app/errors/LoginError';
import { analyticsClient, AnalyticsEvents } from '~/app/utils/analytics-new';
import { SharedOnboardingRoutes } from '@web/utils';
import { LocalStorageKey, setItem } from '~/app/utils/localStorage';
import { setSessionItem } from '~/app/utils/sessionStorage';
import { featureFlags } from '~/app/utils/featureFlags';
import { getUserDetails } from '~/app/utils/network';

/**
 * Helper function to login a user
 * @throws {ChallengeRequiredError} if the user needs to complete a challenge
 * @throws {APIError} if there is an API error
 * @throws {FieldValidationError} if there is a field-level validation error
 * @throws {Error} if there is an unknown error
 */
export async function login(email: string, password: string, token?: string) {
  const recaptchaToken =
    token ??
    (await createReCaptchaToken({
      action: CaptchaAction.LOGIN,
      twofactor: false,
    }));

  const { error, response } = await fetcher.PUT('/api/auth/login/password', {
    body: {
      email,
      password,
      recaptchaToken,
    },
  });

  if (error) {
    const challenges = isMFARequired(error.errors, 'login', {
      email,
      password,
    });

    if (challenges) {
      throw new ChallengeRequiredError(challenges);
    }

    if (isInvalidCredential(error.errors)) {
      throw new LoginError();
    }

    throw new APIError(getResponseErrorMessage(error), response.status, error);
  }

  return createResponse({});
}

function validate(formData: FormData) {
  const email = formData.get('email')?.toString() ?? '';
  const password = formData.get('password')?.toString();
  const hasPassword = formData.get('hasPassword') === 'true';
  const errors: { email?: string } = {};

  if (!hasPassword && !email) {
    errors.email = 'Email is required';
  }

  if (hasPassword && (!email || !password)) {
    errors.email = 'Email and password are required';
  }

  if (Object.keys(errors).length > 0) {
    return { isValid: false, errors, data: null };
  }

  return {
    isValid: true,
    error: null,
    data: { email, password, hasPassword },
  };
}

export type ActionResult = AsyncActionWithoutResponse<typeof action>;

export const action = async ({ request }: ActionFunctionArgs) => {
  const url = new URL(request.url);
  const redirectTo = url.searchParams.get('redirectTo');
  const analytics = analyticsClient();
  const isTestVariant = featureFlags.get('onboardingV2', 'control') === 'test';
  const formData = await request.formData();
  const validationResult = validate(formData);

  if (!validationResult.isValid) {
    return createErrorResponse(validationResult.errors!);
  }

  const { email, hasPassword, password } = validationResult.data!;

  try {
    const { data } = await checkRegistration(email, hasPassword);

    if (data?.hasSaml && !data?.passwordSet) {
      await Promise.allSettled([
        analytics.trackAnonymousEvent(AnalyticsEvents.signInButtonClicked, {
          category: 'Sign Up',
          sub_category: 'Sign In',
          action_type: 'Clicked',
          type: 'SAML',
          page: url.pathname,
        }),
        trackUserLogin('SAML', false),
      ]);

      window.location.replace(buildSamlRedirectURL(email, redirectTo));

      return null;
    } else if (data?.passwordSet) {
      await login(email, password!);

      await Promise.allSettled([
        analytics.trackAnonymousEvent(AnalyticsEvents.signInButtonClicked, {
          category: 'Sign Up',
          sub_category: 'Sign In',
          action_type: 'Clicked',
          type: 'Password',
          page: url.pathname,
        }),
        trackUserLogin('Email'),
      ]);
    }
  } catch (e: unknown) {
    if (e instanceof FieldValidationError) {
      return createErrorResponse(e.errors);
    }

    if (e instanceof ChallengeRequiredError) {
      await Promise.allSettled([
        analytics.trackAnonymousEvent(AnalyticsEvents.signInButtonClicked, {
          category: 'Sign Up',
          sub_category: 'Sign In',
          action_type: 'Clicked',
          type: 'Password',
          page: url.pathname,
        }),
        trackUserLogin('Email'),
      ]);

      return replace(
        Routes.Verify,
        undefined,
        new URLSearchParams({ from: Routes.Login }),
      );
    }

    analytics.trackAnonymousEvent(AnalyticsEvents.signInErrored, {
      category: 'Sign Up',
      sub_category: 'Sign In',
      action_type: 'Errored',
      error: (e as Error).message,
      page: url.pathname,
    });

    if (e instanceof LoginError) {
      return createErrorResponse(e.message);
    }

    if (e instanceof APIError) {
      return createErrorResponse(e.message, null, e.status);
    }

    if (e instanceof Error) {
      return createErrorResponse(e.message);
    }

    return createErrorResponse(ErrorMessages.PLEASE_TRY_AGAIN_LATER);
  }

  if (isTestVariant) {
    const [user, questionnaireRes, discoverRes] = await Promise.all([
      getUserDetails(),
      fetcher.GET('/api/user/v3/questionnaire'),
      fetcher.GET('/api/user/v2/organization/discover'),
    ]);

    if (questionnaireRes.error || discoverRes.error) {
      if (
        questionnaireRes.response.status !== 404 &&
        discoverRes.response.status !== 404
      ) {
        // Only if a questionnaire and org exists, we return an error
        return createErrorResponse(ErrorMessages.PLEASE_TRY_AGAIN_LATER);
      }
    }

    const questionnaire = questionnaireRes.data;
    const discover = discoverRes.data;
    const orgId = questionnaire?.organizationId;

    // if the org we discover is the same as permissions org, they are enterprise user
    const joinedOrg =
      discover?.organizationId === questionnaire?.organizationId;

    setSessionItem(
      'onboarding:user-type',
      joinedOrg || user?.invited ? 'enterprise' : 'self-serve',
    );

    if (orgId) {
      setItem(
        LocalStorageKey.OnboardingOrgId,
        String(questionnaire.organizationId),
      );
    }

    if (!questionnaire?.completedAt) {
      // if a persisted step exists, redirect to it
      // else go to join/create org page (additional logic exists there)
      return replace(
        questionnaire?.data.questionnaireStep
          ? (questionnaire?.data.questionnaireStep as SharedOnboardingRoutes)
          : Routes.JoinCreateOrg,
      );
    }
  }

  // by default, log the user in or redirect to the redirectTo url
  hardRedirect(redirectTo || baseUrl);

  return null;
};
