import { fetcher } from './fetcher';
import { ProductValue } from '~/@types/types';

export type QuestionnaireValues = {
  programmingExperience: string;
  buildingAppsFor: string;
  useCase: string;
  useCaseAiStudio: string;
  writerAppUseCase: string;
  ideaDescription: string;
  hasApplicationIdea: string;
  clientAverageAnnualRevenue: string;
  companyLocation: string;
  department: string;
  industry: string;
  industryOther: string;
  role: string;
  roleOther: string;
  position: string;
  positionOther: string;
  companySize: string;
  companyName: string;
};

export async function getQuestionnaireData(
  orgId: string | null,
  product: string,
): Promise<{
  product?: string;
  data?: Record<string, string>;
  completedAt?: string | null;
  [key: string]: any;
} | null> {
  if (!orgId) return null;

  const response = await fetcher.GET(
    '/api/user/v3/organization/{organizationId}/questionnaire',
    {
      params: {
        path: {
          organizationId: Number(orgId),
        },
      },
    },
  );

  if (response.error) {
    return null;
  }

  const productData = response.data.find((item) => item.product === product);

  return productData || {};
}

export async function updateQuestionnaireData(
  organizationId: number,
  product: typeof ProductValue.type,
  data: { [key: string]: string },
  completed?: boolean,
) {
  try {
    const body: {
      product: typeof ProductValue.type;
      data: { [key: string]: string };
      completed?: boolean;
    } = {
      product,
      data,
    };

    if (completed !== undefined) {
      body.completed = completed;
    }

    await fetcher.PATCH(
      '/api/user/v3/organization/{organizationId}/questionnaire',
      {
        params: {
          path: {
            organizationId,
          },
        },
        body,
      },
    );
  } catch (error) {
    console.error(error);
  }
}
