import type { ILog } from '@writercolab/utils';
import type { RouteError } from '~/@types/types';

const getDatadogClient = async () => {
  if (import.meta.env.PROD && import.meta.env.VITE_DD_ENABLED) {
    const { datadogRum } = await import('@datadog/browser-rum');

    return datadogRum;
  }

  return null;
};

export const datadogLogMiddleware: ILog =
  (namespace: string) =>
  (...msg: any[]) => {
    if (
      import.meta.env.PROD &&
      import.meta.env.VITE_DD_ENABLED &&
      namespace.toLowerCase().includes('error')
    ) {
      getDatadogClient().then((datadogRum) => {
        datadogRum?.addError(msg[0], ...msg);
      });
    }
  };

export const reportRenderError = ({
  name,
  error,
}: {
  name: string;
  error: RouteError<Error>;
}): void => {
  const renderError = new Error(error.error?.message);
  renderError.name = name;
  renderError.cause = error.error;

  getDatadogClient().then((datadogRum) => {
    datadogRum?.addError(renderError);
  });
};

export const initDatadog = async () => {
  const datadogRum = await getDatadogClient();

  datadogRum?.init({
    applicationId: import.meta.env.VITE_DATADOG_APP_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'frontend',
    env: import.meta.env.VITE_ENV?.toLowerCase(),
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum?.startSessionReplayRecording();
};

export const setDatadogUser = async ({
  userId,
  orgId,
}: {
  userId: string;
  orgId: string;
}) => {
  const datadogRum = await getDatadogClient();

  datadogRum?.setUser({
    id: userId,
    orgId,
  });
};
