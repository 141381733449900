import { Button, Input, Separator, Text } from '@writercolab/fe.wds';
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router';
import {
  Form,
  Link,
  useActionData,
  useLoaderData,
  useNavigation,
} from 'react-router';
import { Helmet } from 'react-helmet';
import { Box } from '~/app/components/atoms/Box/Box';
import { Routes } from '~/constants';
import { Banner } from '~/app/components/atoms/Banner/Banner';
import { QueryParams } from '~/app/types';
import { getErrorFromBase64, isValidEmail } from '~/app/utils/errors';
import { useMemo } from 'react';
import { buildSamlRedirectURL } from '~/app/utils/saml';
import { SAML_SSO_ERROR_MESSAGE } from '~/app/utils/messages';
import { box, form, helpLink, helpIcon } from '../../components/form.css';
import { centerSelf, separator } from '../pages.css';
import { hardRedirect } from '~/app/utils/routerUtils';
import { createErrorResponse, createResponse } from '~/app/utils/new/response';
import openTabIcon from '~/assets/open_tab.svg';

export const action = async ({ request }: ActionFunctionArgs) => {
  const url = new URL(request.url);
  const redirectTo = url.searchParams.get('redirectTo');
  const data = await request.formData();
  const email = data.get('email');

  if (!email) {
    return createErrorResponse('Email is required');
  }

  if (!isValidEmail(email.toString())) {
    return createErrorResponse('Enter a valid email');
  }

  hardRedirect(buildSamlRedirectURL(email.toString(), redirectTo));

  return null;
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return createResponse({ searchParams: new URL(request.url).searchParams });
};

function getErrorMessage() {
  const params = new URLSearchParams(window.location.search);
  const error = params.get(QueryParams.enum.fail);

  return error ? getErrorFromBase64(error) : null;
}

export default function SamlPage() {
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const actionData = useActionData() as Awaited<ReturnType<typeof action>>;

  const errorMessage = useMemo(getErrorMessage, []);

  const { state } = useNavigation();
  const isLoading = state === 'submitting';
  return (
    <>
      <Helmet>
        <title>SAML SSO</title>
      </Helmet>
      <div className={centerSelf}>
        <Box className={box} title="SAML SSO">
          <Form method="PUT" className={form}>
            <Input
              name="email"
              label="Email"
              type="email"
              auto
              error={Boolean(actionData?.error)}
              help={
                typeof actionData?.error === 'string'
                  ? actionData?.error
                  : undefined
              }
              autoComplete="email"
            />
            {/* /THIS IS HACK for using custom message for saml sso error. When BE update it we can switch to regular way/ */}
            {errorMessage && (
              <Banner variant="error">
                {SAML_SSO_ERROR_MESSAGE}{' '}
                <a
                  href="https://support.writer.com/article/283-troubleshooting-saml-authorization-errors"
                  target="_blank"
                  className={helpLink}
                >
                  <Text as="span" size="sm" weight={'bold'}>
                    Help center
                  </Text>
                  <img
                    src={openTabIcon}
                    alt="help center"
                    className={helpIcon}
                  />
                </a>
              </Banner>
            )}
            <Button auto type="submit" loading={isLoading} disabled={isLoading}>
              Sign in
            </Button>
          </Form>
          <Separator className={separator}>or</Separator>
          <Link
            to={`${Routes.Login}?${loaderData.data?.searchParams.toString()}`}
          >
            <Button variant="outline" auto>
              Sign in with email or Google
            </Button>
          </Link>
        </Box>
      </div>
    </>
  );
}
