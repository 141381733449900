import { APIError } from '~/app/errors/APIError';
import { FieldValidationError } from '~/app/errors/FieldValidationError';
import { fetcher } from '~/app/utils/fetcher';
import { ErrorMessages } from '~/app/utils/messages';
import { createResponse } from '~/app/utils/new/response';

export async function checkRegistration(email: string, hasPassword: boolean) {
  const { data, error, response } = await fetcher.GET(
    '/api/user/v2/registration/check',
    {
      method: 'PUT',
      params: { query: { email } },
      cache: 'no-cache',
    },
  );

  if (data?.exists === false) {
    throw new FieldValidationError({
      email: ErrorMessages.INCORRECT_USERNAME_OR_PASSWORD,
    });
  }

  // show password field
  if (data?.passwordSet && !hasPassword) {
    throw new FieldValidationError({ withPassword: true });
  }

  if (error) {
    throw new APIError(response.statusText, response.status, error);
  }

  return createResponse(data);
}
