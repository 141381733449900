declare global {
  interface Window {
    APP_ROOT: string;
    API_URL: string;
    RECAPTCHA_ENTEPRISE_KEY: string;
  }
}

export const config = {
  APP_ROOT: window.APP_ROOT,
  API_URL: window.API_URL,
  RECAPTCHA_ENTEPRISE_KEY: window.RECAPTCHA_ENTEPRISE_KEY,
};

export default config;
