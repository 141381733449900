import type { components } from '@writercolab/network';
import { ERROR_CODES, ERRORS } from './messages';

export type ServerError = components['schemas']['FailMessage'];

export function isAccountNotFound(errors: ServerError[]) {
  return errors.some(
    (error) =>
      error.key === ERRORS.InvalidPassword ||
      (error.key === 'fail.resource.generic' &&
        error.description.includes('is not found')),
  );
}

export function isInvalidCredential(errors: ServerError[]) {
  return errors.some((error) => error.key === ERRORS.InvalidPassword);
}

export function isAlreadyRegistered(errors: ServerError[]) {
  return errors.some((error) => error.key === ERRORS.UserAlreadyRegistered);
}

/**
 * Use this to handle errors from `fetcher` responses.
 */
export function getResponseErrorMessage(
  res: components['schemas']['api_tapir_FailResponse'],
) {
  if (res.errors.length > 0 && res.errors[0].key in ERROR_CODES) {
    return ERROR_CODES[res.errors[0].key as keyof typeof ERROR_CODES];
  }

  return 'An error occurred';
}

// same regexps as the backend
export const emailRe = /^([a-zA-Z0-9_.+'-]+)@([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/;
export const passwordRe = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/;

export function isValidEmail(email: string) {
  return emailRe.test(email);
}

/**
 * Ensures that the string contains at least one uppercase letter,
 * one lowercase letter, one digit, and is at least 8 characters long.
 */
export function isValidPassword(password: string) {
  return passwordRe.test(password);
}

/**
 * Create uniform field-level error responses across the onboarding experience.
 * @param messages an object describing the fields and error messages for each field
 * @param status optionally set the status so something other than 400
 */
export function createFieldErrorResponse<T extends Record<string, string>>(
  messages: T,
  status = 400,
) {
  return {
    success: false,
    error: messages,
    status,
  };
}

/**
 * Parses a base64-encoded error string and returns the parsed error object.
 *
 * @param str the base64-encoded error string to parse.
 * @returns the parsed error object
 */
const parseBase64Error = (
  str: string,
): components['schemas']['api_tapir_FailResponse'] | undefined => {
  // absolutely no clue why we need to replace null bytes
  const errorJson = window.atob(str).replace(/\0/g, '');

  if (errorJson) {
    try {
      return JSON.parse(errorJson);
    } catch {
      // ignore
    }
  }

  return undefined;
};

export const getErrorFromBase64 = (str: string): string => {
  const error = parseBase64Error(str);

  return error ? getResponseErrorMessage(error) : '';
};
