/**
 * A generic UI validation error. This should be used in conjunction
 * with `{@link createErrorResponse}`.
 */
export class FieldValidationError<
  T extends Record<string, string | boolean | number> = Record<
    string,
    string | boolean | number
  >,
> extends Error {
  public readonly errors: T;
  public readonly name = 'FieldValidationError';

  constructor(errors: T) {
    super('Validation error');
    this.errors = errors;
  }
}
