import { Button, Input, Text, Separator } from '@writercolab/fe.wds';

import {
  Form,
  Link,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigation,
} from 'react-router';
import { Box } from '~/app/components/atoms/Box/Box';
import { Routes } from '~/constants';

import { GoogleButton } from '~/app/components/atoms/Button/GoogleButton';
import { handleGoogleAuth } from '~/app/utils/saml';
import { box, form } from '../../components/form.css';
import { forgotPassword, mt3, registerText } from './login.css';
import { centerSelf, separator } from '../pages.css';
import { Helmet } from '~/app/components/molecules/Helmet/Helmet';
import { Banner } from '~/app/components/atoms/Banner/Banner';
import type { LoaderResult } from './loader';
import type { ActionResult } from './action';
import { useRef } from 'react';
import { setSessionItem } from '~/app/utils/sessionStorage';
import { analyticsClient, AnalyticsEvents } from '~/app/utils/analytics-new';

const ForgotPassword: React.FC<{ onClick: VoidFunction }> = ({ onClick }) => {
  return (
    <Link
      to={Routes.ResetPassword}
      className={forgotPassword}
      onClick={onClick}
    >
      <Text as="span" size="sm">
        Forgot password?
      </Text>
    </Link>
  );
};

export { action } from './action';
export { loader } from './loader';

export default function LoginPage() {
  const location = useLocation();
  const loaderData = useLoaderData() as LoaderResult;
  const actionData = useActionData() as ActionResult;
  const analytics = analyticsClient();
  const { state } = useNavigation();
  const email = useRef<HTMLInputElement>(null);

  const isLoading = state !== 'idle';

  const redirectTo =
    loaderData.data?.searchParams.get('redirectTo') || undefined;

  const error = loaderData?.error ?? actionData?.error;
  const successMessage =
    loaderData?.success && ((loaderData?.data as any)?.message as string);

  return (
    <>
      <Helmet
        title="Sign in to your Writer account"
        description="Don't have an account yet? Sign up for free and try the full-stack generative AI platform built for enterprises."
      />
      <div className={centerSelf}>
        <Box className={box} title="Sign in to your account">
          <Form method="PUT" className={form}>
            <input
              name="hasPassword"
              readOnly
              hidden
              value={`${actionData?.error?.withPassword ? 'true' : 'false'}`}
            />
            <Input
              name="email"
              label="Email"
              type="email"
              auto
              autoComplete="email"
              ref={email}
              error={Boolean(actionData?.error?.email)}
              help={actionData?.error?.email ?? ''}
            />
            {actionData?.error?.withPassword && (
              <Input
                label="Password"
                name="password"
                type="password"
                autoComplete="current-password"
                autoFocus={actionData.data?.withPassword}
                auto
                help={
                  <ForgotPassword
                    onClick={() => {
                      if (email.current?.validity.valid) {
                        setSessionItem(
                          'onboarding:reset-email',
                          email.current?.value,
                        );
                      }
                    }}
                  />
                }
              />
            )}
            <Button auto type="submit" loading={isLoading} disabled={isLoading}>
              Sign in
            </Button>
            {typeof error === 'string' ? (
              <Banner variant="error">{error}</Banner>
            ) : null}
            {typeof successMessage === 'string' && successMessage.length ? (
              <Banner variant="success">{successMessage}</Banner>
            ) : null}
          </Form>
          <Separator className={separator}>or</Separator>
          <div>
            <GoogleButton
              label="Sign in with Google"
              onClick={async (e) => {
                e.preventDefault();

                analytics
                  .trackAnonymousEvent(AnalyticsEvents.signInButtonClicked, {
                    category: 'Sign Up',
                    sub_category: 'Sign In',
                    action_type: 'Clicked',
                    type: 'Google',
                    page: location.pathname,
                  })
                  .catch(console.error);

                handleGoogleAuth(redirectTo);
              }}
            />
            <Link
              to={`${Routes.Saml}?${loaderData.data?.searchParams?.toString()}`}
            >
              <Button variant="outline" auto className={mt3}>
                Sign in with SAML single sign-on
              </Button>
            </Link>
          </div>
          <Text size="sm" className={registerText}>
            Don&apos;t have an account yet?{' '}
            <Link to={Routes.Register}>
              <Text size="sm" as="span" weight="bold">
                Sign up
              </Text>
            </Link>
          </Text>
        </Box>
      </div>
    </>
  );
}
