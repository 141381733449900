export function getUtmParamsFromRequest(request: Request) {
  const params = new URL(request.url).searchParams;

  const utmSource = params.get('utm_source');
  const utmMedium = params.get('utm_medium');
  const utmCampaign = params.get('utm_campaign');
  const utmTerm = params.get('utm_term');
  const utmContent = params.get('utm_content');
  const utmReferrer = params.get('utm_referrer');
  const pageLabel = params.get('page_label');
  const ref = params.get('ref');
  const gclid = params.get('gclid');

  return {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_term: utmTerm,
    utm_content: utmContent,
    utm_referrer: utmReferrer,
    referrer: request.referrer ?? request.headers.get('Referer') ?? '',
    page_label: pageLabel,
    ref,
    gclid,
  } as const;
}

export function stringify(utm: ReturnType<typeof getUtmParamsFromRequest>) {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(utm)) {
    if (value) {
      searchParams.append(key, value);
    }
  }

  return searchParams.toString();
}
