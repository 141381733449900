import type { InvitedTo } from '~/constants';
import type { SignUpFormData } from '../pages/register/schema';
import type { Challenge } from './mfa';
import { getUtmParamsFromRequest } from './utm';

/**
 * A tiny typed localStorage wrapper. Add new items
 * to {@link SessionItems} to get type safety.
 */
export type SessionItems = {
  'onboarding:account':
    | ({ kind: 'login' } & Pick<SignUpFormData, 'email' | 'password'>)
    | ({ kind: 'register' } & SignUpFormData);
  'onboarding:utm': ReturnType<typeof getUtmParamsFromRequest>;
  'onboarding:challenge': Challenge[];
  'onboarding:token-sent': boolean;
  'onboarding:invite-app': InvitedTo;
  'onboarding:verification-sent-at': { primary: number; secondary: number };
  'ais:discount': string; // DISCOUNT_SESSION_KEY
  'onboarding:reset-email': string;
  'onboarding:user-type': 'enterprise' | 'self-serve';
};

export function setSessionItem<K extends keyof SessionItems>(
  key: K,
  value: SessionItems[K] | string,
) {
  window.sessionStorage.setItem(
    key,
    typeof value === 'string' ? value : JSON.stringify(value),
  );
}

export function getSessionItem<K extends keyof SessionItems>(key: K) {
  const value = window.sessionStorage.getItem(key);

  try {
    return JSON.parse(value ?? 'null') as SessionItems[K];
  } catch {
    return typeof value === typeof ({} as SessionItems[K])
      ? (value as SessionItems[K])
      : (value as string);
  }
}

export function hasSessionItem(key: keyof SessionItems) {
  return window.sessionStorage.getItem(key) !== null;
}

export function removeSessionItem(key: keyof SessionItems) {
  window.sessionStorage.removeItem(key);
}

const keysToRemove: ReadonlyArray<keyof SessionItems> = [
  'onboarding:challenge',
  'onboarding:account',
  'onboarding:verification-sent-at',
  'onboarding:invite-app',
  'ais:discount',
  'onboarding:token-sent',
  'onboarding:reset-email',
];

export function clearSessionStorage() {
  keysToRemove.forEach((key) => window.sessionStorage.removeItem(key));
}
