import { SvgIcon } from '~/app/components/icon';
import financialServicesIcon from '~/assets/icons/industry/financial-services.svg';
import lifeSciencesIcon from '~/assets/icons/industry/life-sciences.svg';
import healthcareSystemsIcon from '~/assets/icons/industry/healthcare-systems.svg';
import healthInsuranceIcon from '~/assets/icons/industry/health-insurance.svg';
import retailConsumerGoodsIcon from '~/assets/icons/industry/retail-consumer-goods.svg';
import technologyIcon from '~/assets/icons/industry/technology.svg';
import mediaIcon from '~/assets/icons/industry/media.svg';
import professionalServicesIcon from '~/assets/icons/industry/professional-services.svg';
import otherIcon from '~/assets/icons/industry/other.svg';
import { Enum } from '@writercolab/utils';

export const Industries = new Enum(
  'financial-services',
  'life-sciences',
  'healthcare-systems',
  'health-insurance',
  'retail-consumer-packaged-goods',
  'technology',
  'media',
  'professional-services',
  'other',
);

export const industries = [
  {
    id: Industries.enum['financial-services'],
    icon: <SvgIcon src={financialServicesIcon} alt="Financial services" />,
    title: 'Financial services',
  },
  {
    id: Industries.enum['life-sciences'],
    icon: <SvgIcon src={lifeSciencesIcon} alt="Life sciences" />,
    title: 'Life sciences',
  },
  {
    id: Industries.enum['healthcare-systems'],
    icon: <SvgIcon src={healthcareSystemsIcon} alt="Healthcare systems" />,
    title: 'Healthcare systems',
  },
  {
    id: Industries.enum['health-insurance'],
    icon: <SvgIcon src={healthInsuranceIcon} alt="Health insurance" />,
    title: 'Health insurance',
  },
  {
    id: Industries.enum['retail-consumer-packaged-goods'],
    icon: (
      <SvgIcon
        src={retailConsumerGoodsIcon}
        alt="Retail and consumer packaged goods"
      />
    ),
    title: 'Retail & consumer packaged goods',
  },
  {
    id: Industries.enum['technology'],
    icon: <SvgIcon src={technologyIcon} alt="Technology" />,
    title: 'Technology',
  },
  {
    id: Industries.enum['media'],
    icon: <SvgIcon src={mediaIcon} alt="Media" />,
    title: 'Media',
  },
  {
    id: Industries.enum['professional-services'],
    icon: (
      <SvgIcon src={professionalServicesIcon} alt="Professional services" />
    ),
    title: 'Professional services',
  },
  {
    id: Industries.enum['other'],
    icon: <SvgIcon src={otherIcon} alt="Other" />,
    title: 'Other',
  },
];

export const industriesMap = new Map(
  industries.map((industry) => [industry.id, industry.title]),
);
