/**
 * A small `omit` function that picks keys off an object
 * and returns a new object without those keys. We don't need
 * `lodash` for this 🙃
 */
export function omit<T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Omit<T, K> {
  const result = { ...obj };

  for (const key of keys) {
    delete result[key];
  }

  return result;
}
