import { Enum } from '@writercolab/utils';

export const QueryParams = new Enum(
  'onboarding',
  'fail',
  'failUrl',
  'email',
  'login_source',
  'signupType',
  'redirectTo',
  'error_code',
  'error_message',
);

export const SignupTypes = new Enum('saml', 'google');
