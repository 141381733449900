import type { components } from '@writercolab/network';

export type TAPIError = {
  errors: [
    components['schemas']['FailMessage'],
    ...components['schemas']['FailMessage'][],
  ];
  extras: unknown;
  tpe: string;
};

export class APIError extends Error {
  public readonly error;
  public readonly status: number;

  constructor(message: string, status: number, error?: TAPIError) {
    super(message);
    this.name = 'APIError';
    this.status = status;
    this.error = error;
  }
}
