type SvgIconProps = {
  src: string;
  alt: string;
  width?: number;
  height?: number;
};

export const SvgIcon = ({ src, alt, width, height }: SvgIconProps) => {
  return <img src={src} alt={alt} width={width} height={height} />;
};
