import { analyticsClient } from './client';
import { AnalyticsEvents } from './events';
import { getUserDetails } from '../network';
import { getSessionItem } from '../sessionStorage';
import { TSubscriptionType } from '~/@types/types';
import { getItem, LocalStorageKey } from '../localStorage';
import {
  Industries,
  industries,
} from '~/app/pages/question-industry/industries';
import { roleCategories, Roles } from '~/app/pages/question-role/roles';
import { Positions, positions } from '~/app/pages/question-position/positions';
import { companySize } from '~/app/pages/question-position/company-size';

export type BuiltInGAEvents =
  | 'exception'
  | 'custom-form-submit'
  | (string & {});

export const trackQuestionItemSelection = ({
  toggleType,
  question,
  value,
  prevValue,
  userId,
  page,
}: {
  toggleType: 'Selected' | 'Unselected';
  question: string;
  value?: string;
  prevValue?: string;
  userId: number;
  page: string;
}) => {
  const isSelected = toggleType === 'Selected';
  const analytics = analyticsClient();
  const eventName = isSelected
    ? AnalyticsEvents.questionnaireOptionSelected
    : AnalyticsEvents.questionnaireOptionUnselected;

  analytics.track(eventName, {
    category: 'Sign Up',
    sub_category: 'Onboarding',
    action_type: toggleType,
    user_id: String(userId),
    [question]: isSelected ? value : prevValue,
    page,
  });
};

export function trackGAEvent(
  name: BuiltInGAEvents,
  data: {
    properties?: string;
    context?: string;
    eventCategory: 'Form Submission' | (string & {});
    userId: string;
    email: string;
    eventAction: 'Login' | 'Register' | (string & {});
    eventLabel: string;
    useCase?: string;
    department?: string;
    [key: string]: string | undefined;
  },
) {
  window.dataLayer?.push({
    event: name,
    ...data,
  });
}

export async function trackGACompletedOnboarding(questionData: {
  industry: string;
  function: string;
  seniority?: string;
  employees?: string;
  company_name?: string;
}) {
  const user = await getUserDetails();
  const subscriptionType = getSessionItem(
    'onboarding:user-type',
  ) as TSubscriptionType;

  const type = getItem(LocalStorageKey.OnboardingRegistrationType);
  const eventAction =
    subscriptionType === 'enterprise' ? 'Register Enterprise' : 'Register SS';

  if (user && user.email) {
    trackGAEvent('custom-form-submit', {
      email: user.email,
      eventCategory: 'Form Submission',
      eventAction,
      userId: String(user.id),
      eventLabel: type,
      ...questionData,
    });
  }
}

export function trackPageView() {
  const analytics = analyticsClient();
  const organizationId = getItem(LocalStorageKey.OnboardingOrgId);
  const isAuthenticated = !!getItem(LocalStorageKey.FeatureFlags);

  const properties = {
    page_name: document.title.split('|')[1]?.trim() || document.title,
    path: window.location.pathname,
    referrer: document.referrer,
    search: window.location.search,
    title: document.title,
    url: window.location.href,
  };

  if (isAuthenticated) {
    analytics.page({
      name: properties.page_name,
      organizationId: organizationId ? Number(organizationId) : null,
      properties,
    });
  }
}

export function getGAIndustry(
  questionnaireData: Record<string, string> | undefined,
) {
  const industryOther = questionnaireData?.industry === Industries.enum.other;

  return industryOther
    ? questionnaireData?.industryOther || ''
    : industries.find((industry) => industry.id === questionnaireData?.industry)
        ?.title || '';
}

export function getGAFunction(
  questionnaireData: Record<string, string> | undefined,
) {
  const roleOther = questionnaireData?.role === Roles.enum.other;
  const industry = questionnaireData?.industry as keyof typeof roleCategories;

  return roleOther
    ? questionnaireData?.roleOther || ''
    : roleCategories[industry].find(
        (role) => role.id === questionnaireData?.role,
      )?.title || '';
}

export function getGASeniority(
  questionnaireData: Record<string, string> | undefined,
) {
  const positionOther = questionnaireData?.position === Positions.enum.other;

  return positionOther
    ? questionnaireData?.positionOther || ''
    : positions.find((position) => position.id === questionnaireData?.position)
        ?.title || '';
}

export function getGAEmployees(
  questionnaireData: Record<string, string> | undefined,
) {
  return (
    companySize.find((size) => size.id === questionnaireData?.companySize)
      ?.title || ''
  );
}
