import { Enum } from '@writercolab/utils';

export const CompanySize = new Enum(
  '1-100',
  '100-500',
  '500-1000',
  '1000-5000',
  '5000-10000',
  '10000+',
);

export const companySize = [
  {
    id: CompanySize.enum['1-100'],
    title: '1-100 people',
  },
  {
    id: CompanySize.enum['100-500'],
    title: '100-500 people',
  },
  {
    id: CompanySize.enum['500-1000'],
    title: '500-1,000 people',
  },
  {
    id: CompanySize.enum['1000-5000'],
    title: '1,000-5,000 people',
  },
  {
    id: CompanySize.enum['5000-10000'],
    title: '5,000-10,000 people',
  },
  {
    id: CompanySize.enum['10000+'],
    title: '10,000+ people',
  },
];
