import { Enum } from '@writercolab/utils';
import { Industries } from '../question-industry/industries';

export const roleCategories = {
  [Industries.enum['financial-services']]: [
    { id: 'investment-banking', title: 'Investment Banking' },
    { id: 'asset-management', title: 'Asset Management' },
    { id: 'wealth-management', title: 'Wealth Management' },
    { id: 'marketing', title: 'Marketing' },
    { id: 'sales-enablement', title: 'Sales + Enablement' },
    { id: 'technology', title: 'Technology' },
    { id: 'hr', title: 'HR' },
    { id: 'legal', title: 'Legal' },
    { id: 'other', title: 'Other (please specify)' },
  ],
  [Industries.enum['life-sciences']]: [
    { id: 'sales-enablement', title: 'Sales + Enablement' },
    { id: 'marketing', title: 'Marketing' },
    { id: 'market-access', title: 'Market Access' },
    { id: 'medical-affairs', title: 'Medical Affairs' },
    { id: 'research-development', title: 'Research + Development' },
    { id: 'technology', title: 'Technology' },
    { id: 'hr', title: 'HR' },
    { id: 'legal', title: 'Legal' },
    { id: 'finance', title: 'Finance' },
    { id: 'regulatory-affairs', title: 'Regulatory Affairs' },
    { id: 'compliance', title: 'Compliance' },
    { id: 'manufacturing', title: 'Manufacturing' },
    { id: 'supply-chain', title: 'Supply Chain' },
    { id: 'other', title: 'Other (please specify)' },
  ],
  [Industries.enum['healthcare-systems']]: [
    { id: 'clinician', title: 'Clinician' },
    { id: 'marketing', title: 'Marketing' },
    {
      id: 'patient-experience-engagement',
      title: 'Patient Experience + Engagement',
    },
    { id: 'care-management-delivery', title: 'Care Management + Delivery' },
    { id: 'provider-experience', title: 'Provider Experience' },
    { id: 'research-trials', title: 'Research + Trials' },
    { id: 'operations', title: 'Operations' },
    { id: 'supply-chain-procurement', title: 'Supply Chain + Procurement' },
    { id: 'finance-revenue', title: 'Finance + Revenue' },
    { id: 'regulatory-compliance', title: 'Regulatory + Compliance' },
    { id: 'technology', title: 'Technology' },
    { id: 'other', title: 'Other (please specify)' },
  ],
  [Industries.enum['health-insurance']]: [
    {
      id: 'member-aquisition-enrollment',
      title: 'Member Aquisition + Enrollment',
    },
    { id: 'member-provider-support', title: 'Member + Provider Support' },
    { id: 'claims-payments', title: 'Claims + Payments' },
    { id: 'population-health', title: 'Population Health' },
    {
      id: 'provider-relations-contracting',
      title: 'Provider Relations + Contracting',
    },
    {
      id: 'care-utilization-management',
      title: 'Care + Utilization Management',
    },
    { id: 'benefit-plan-design', title: 'Benefit Plan Design' },
    { id: 'marketing', title: 'Marketing' },
    { id: 'data-analytics', title: 'Data + Analytics' },
    { id: 'sales-enablement', title: 'Sales + Enablement' },
    { id: 'technology', title: 'Technology' },
    { id: 'operations', title: 'Operations' },
    { id: 'other', title: 'Other (please specify)' },
  ],
  [Industries.enum['retail-consumer-packaged-goods']]: [
    { id: 'marketing', title: 'Marketing' },
    { id: 'sales-enablement', title: 'Sales + Enablement' },
    { id: 'technology', title: 'Technology' },
    { id: 'product-supply', title: 'Product Supply' },
    { id: 'finance', title: 'Finance' },
    { id: 'legal', title: 'Legal' },
    { id: 'hr', title: 'HR' },
    { id: 'purchasing', title: 'Purchasing' },
    { id: 'customer-support', title: 'Customer Support' },
    { id: 'other', title: 'Other (please specify)' },
  ],
  [Industries.enum.technology]: [
    { id: 'product', title: 'Product' },
    { id: 'design', title: 'Design' },
    { id: 'engineering', title: 'Engineering' },
    { id: 'data-analytics', title: 'Data + Analytics' },
    { id: 'marketing', title: 'Marketing' },
    { id: 'content-strategy', title: 'Content Strategy' },
    { id: 'operations', title: 'Operations' },
    { id: 'customer-support', title: 'Customer Support' },
    { id: 'legal', title: 'Legal' },
    { id: 'hr', title: 'HR' },
    { id: 'sales-enablement', title: 'Sales + Enablement' },
    { id: 'other', title: 'Other (please specify)' },
  ],
  [Industries.enum.media]: [
    { id: 'marketing', title: 'Marketing' },
    { id: 'sales-enablement', title: 'Sales + Enablement' },
    { id: 'technology', title: 'Technology' },
    { id: 'finance', title: 'Finance' },
    { id: 'legal', title: 'Legal' },
    { id: 'hr', title: 'HR' },
    { id: 'customer-support', title: 'Customer Support' },
    { id: 'other', title: 'Other (please specify)' },
  ],
  [Industries.enum['professional-services']]: [
    { id: 'consultant', title: 'Consultant' },
    { id: 'project-management', title: 'Project Management' },
    { id: 'technology', title: 'Technology' },
    { id: 'marketing', title: 'Marketing' },
    { id: 'sales-enablement', title: 'Sales + Enablement' },
    { id: 'legal', title: 'Legal' },
    { id: 'hr', title: 'HR' },
    { id: 'learning-development', title: 'Learning + Development' },
    { id: 'customer-support', title: 'Customer Support' },
    { id: 'other', title: 'Other (please specify)' },
  ],
  [Industries.enum.other]: [
    { id: 'marketing', title: 'Marketing' },
    { id: 'sales-enablement', title: 'Sales + Enablement' },
    { id: 'technology', title: 'Technology' },
    { id: 'legal', title: 'Legal' },
    { id: 'hr', title: 'HR' },
    { id: 'customer-support', title: 'Customer Support' },
    { id: 'other', title: 'Other (please specify)' },
  ],
};

export const Roles = new Enum('other');

export type TRoleCategory = {
  id: string;
  title: string;
};
