import { memoize } from './memoize';
import config from './dynamicConfig';
export enum CaptchaAction {
  CHECK_SIGNUP = 'checkSignUp',
  AUTH_INVITATION = 'authInvitation',
  INVITATION_PREPARE = 'invitationPrepare',
  PASSWORD_RECOVER = 'passwordRecover',
  LOGIN = 'login',
  REGISTER = 'register',
}

export type RecaptchaAttributes = {
  twofactor?: boolean;
  action: CaptchaAction;
};

/**
 * Create a reCAPTCHA token. Returns an empty string is reCAPTCHA is disabled.
 * @param attrs
 * @returns Promise resolving to reCaptcha token string.
 * @throws Throws an error if `grecaptcha` is not available on window.
 */
export const createReCaptchaToken = async (
  attrs: RecaptchaAttributes,
): Promise<string> => {
  if (import.meta.env.VITE_ENABLE_RECAPTCHA === 'false') {
    return '';
  }

  if (!window.grecaptcha) {
    throw new Error('grecaptcha is not available on window');
  }

  await new Promise((resolve) =>
    window.grecaptcha?.enterprise.ready(() => {
      resolve(true);
    }),
  );

  return window.grecaptcha.enterprise.execute(
    config.RECAPTCHA_ENTEPRISE_KEY,
    attrs,
  );
};

/**
 * Gets the MFA handle we use to challenge accounts, verify, etc. This handle
 * is automatically memoized by the token. Changing the token will create
 * a new handle.
 * @param token the challenge token from MFA verification errors.
 */
export const createVerificationHandle = memoize((token: string) => {
  return (
    window.grecaptcha?.enterprise as any
  ).eap.initTwoFactorVerificationHandle(config.RECAPTCHA_ENTEPRISE_KEY, token);
});
