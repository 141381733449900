/* v8 ignore next 31 */
import { Heading, Text } from '@writercolab/fe.wds';
import {
  box,
  description as descriptionStyle,
  srOnly as srOnlyStyle,
} from './box.css';
import cx from 'clsx';

/**
 * Supports both main variants of the box. This includes
 * the bordered smaller box used for login and sign up
 * pages and the borderless version for the questionnaire.
 * @returns
 */
export const Box: React.FC<{
  children: React.ReactNode;
  className?: string;
  title?: string;
  description?: string;
  srOnly?: boolean;
}> = ({ children, className, title, description, srOnly }) => {
  return (
    <div className={cx(box, className)}>
      {title && (
        <Heading className={cx(srOnly && srOnlyStyle)} variant="h2">
          {title}
        </Heading>
      )}
      {description && (
        <Text className={cx(descriptionStyle, srOnly && srOnlyStyle)} size="md">
          {description}
        </Text>
      )}
      {children}
    </div>
  );
};
