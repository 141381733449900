import cx from 'clsx';
import { banner } from './banner.css';
import check from './check.svg';
import error from './error.svg';
import { Text } from '@writercolab/fe.wds';

type BannerProps = React.HTMLAttributes<HTMLDivElement> & {
  variant: 'success' | 'info' | 'error';
};

const Icon: React.FC<Pick<BannerProps, 'variant'>> = ({ variant }) => {
  switch (variant) {
    case 'success':
      return <img src={check} width={18} height={18} alt="Success" />;
    case 'error':
      return <img src={error} width={18} height={18} alt="Error" />;
    default:
      return null;
  }
};

export const Banner: React.FC<BannerProps> = ({
  variant,
  children,
  className,
  ...rest
}) => {
  return (
    <div className={cx(banner({ variant }), className)} {...rest}>
      <Icon variant={variant} />
      <Text size="sm">{children}</Text>
    </div>
  );
};
