import { getCache, hasCache, setCache } from './cache';
import { fetcher } from './fetcher';

/**
 * Check if the user has the required permissions to access the route.
 * The response will be cached in-memory after the first successful call.
 */
export async function getPermissions() {
  if (hasCache('permissions')) {
    return getCache('permissions');
  }

  const res = await fetcher.GET('/api/auth/permission');

  if (!res.error) {
    setCache('permissions', res.data);
  }

  return res.data;
}

export async function getUserDetails() {
  if (hasCache('userProfile')) {
    return getCache('userProfile');
  }

  const res = await fetcher.GET('/api/user/v2/profile');

  if (!res.error) {
    setCache('userProfile', res.data);
  }

  return res.data;
}

/**
 * Remove trailing slash from the URL.
 */
export function normalizeUrl(url: string) {
  if (url.endsWith('/')) {
    return url.slice(0, -1);
  }

  return url;
}
