function baseResponse<
  T extends Record<string, any>,
  E extends string | Record<string, any> | null = null,
>(message: E, data: T | null, status = 200) {
  return {
    success: status < 400,
    status,
    error: message,
    data,
  };
}

export function createResponse<T extends Record<string, any>>(
  data: T,
  status = 200,
) {
  return baseResponse(null, data, status);
}

/**
 * Create uniform error responses across the onboarding experience.
 * @param message The error message to return
 * @param status Set the response status
 */
export function createErrorResponse<
  T extends Record<string, any>,
  E extends string | Record<string, any>,
>(message: E, data?: T | null, status = 400) {
  return baseResponse<T, E>(message, data ?? null, status);
}
