/* v8 ignore next 22 */
import { Button } from '@writercolab/fe.wds';
import google from '~/assets/google.svg';

export const GoogleButton: React.FC<
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'> & {
    label: string;
  }
> = ({ label, ...rest }) => {
  return (
    // @ts-expect-error fix in WDS
    <Button
      auto
      variant="outline"
      leftIcon={<img src={google} alt="google logo" />}
      {...rest}
    >
      {label}
    </Button>
  );
};
