import { AnalyticsService } from '@writercolab/analytics';
import { E_CLIENT_ID, E_INTEGRATION_TYPE } from '@writercolab/types';
import { TAnalyticEventParams, TDefaultAnalyticEventParams } from './events';

import { fetcher } from '../fetcher';
import { RequestServiceInitialize } from '@writercolab/network';
import { getItem, LocalStorageKey } from '../localStorage';
import { getSessionItem } from '../sessionStorage';
import { getUtmParamsFromRequest } from '../utm';
import type { TSubscriptionType } from '~/@types/types';

const analyticsService = new AnalyticsService<TAnalyticEventParams>(
  { post: fetcher.POST } as unknown as RequestServiceInitialize['api'],
  {
    clientId: E_CLIENT_ID.enum.QordobaFE,
    integrationType: E_INTEGRATION_TYPE.enum.DEFAULT,
  },
);

let instance: ReturnType<
  typeof analyticsService.withContext<TDefaultAnalyticEventParams>
> | null = null;

export const analyticsClient = () => {
  const orgId = getItem(LocalStorageKey.OnboardingOrgId);
  const userType =
    (getSessionItem('onboarding:user-type') as TSubscriptionType) || null;
  const utm = getSessionItem('onboarding:utm') as ReturnType<
    typeof getUtmParamsFromRequest
  >;

  if (instance) {
    return instance;
  }

  instance = analyticsService.withContext<TDefaultAnalyticEventParams>({
    organization_id: orgId ? Number(orgId) : null,
    subscription_type: userType,
    utm: {
      source: utm?.utm_source,
      medium: utm?.utm_medium,
      campaign: utm?.utm_campaign,
      content: utm?.utm_content,
      term: utm?.utm_term,
      referrer: utm?.utm_referrer,
    },
  });

  return instance;
};

export const resetAnalyticsContext = () => {
  instance = null;
};
