import type { Routes } from '~/constants';
import type { GAActionType } from './analytics';
import { OnboardingLocalStorageKey } from '@web/utils';
import { type TFeatureFlags } from './featureFlags';

enum InternalLocalStorageKey {
  /**
   * Used to track to `qToken` cookie during dev mode. This is NOT
   * applicable outside of dev mode.
   */
  QToken = 'qToken',

  /**
   * Tracks the invite payload so we can recover things between page loads
   * should such situations arise.
   */
  OnboardingInvite = 'onboarding:invite',

  /**
   * Used for GTM and Segment tracking. This tells us the plan the user chose:
   * - Business Workspaces
   * - AI Studio
   */
  OnboardingPlan = 'onboarding:plan',

  /**
   * Used for GTM to track how the user registered:
   * - Email
   * - SAML
   * - Google
   */
  OnboardingRegistrationType = 'onboarding:registration',

  /**
   * Whether the user has completed the questionnaire
   */
  OnboardingCompletedQuestionnaire = 'onboarding:completed-questionnaire',

  /**
   * Used for GTM to track the user's experience type for AI Studio:
   * - Beginner
   * - Intermediate
   * - Advanced
   */
  OnboardingRegistrationAISExperience = 'onboarding:ais:experience',

  /**
   * Tracks the legacy onboarding status during the transitional period
   * where we support all three login experiences:
   * - WA
   * - AIS
   * - Onboarding
   */
  LegacyOnboardingState = 'onboardingState',

  /**
   * A local cache of LaunchDarkly feature flags
   */
  FeatureFlags = 'featureFlagsCache',
}

export const LocalStorageKey = {
  ...InternalLocalStorageKey,
  ...OnboardingLocalStorageKey,
};

/**
 * A tiny typed localStorage wrapper. Add new items
 * to {@link Items} to get type safety.
 */
type Items = {
  [LocalStorageKey.OnboardingOrgId]: string;
  [LocalStorageKey.OnboardingOrgName]: string;
  [LocalStorageKey.OnboardingPlan]: string;
  [LocalStorageKey.OnboardingRegistrationAISExperience]: string;
  [LocalStorageKey.OnboardingRegistrationType]: GAActionType;
  [LocalStorageKey.OnboardingSkipOrgCreation]: boolean;
  [LocalStorageKey.OnboardingState]: 'complete' | 'incomplete';
  [LocalStorageKey.FeatureFlags]: TFeatureFlags;
  [LocalStorageKey.OnboardingCompletedQuestionnaire]: boolean;

  /**
   * Only applicable in development mode.
   */
  [LocalStorageKey.QToken]: string;

  /**
   * Set the current step of the onboarding process.
   */
  [LocalStorageKey.OnboardingStep]: Routes;

  /**
   * Set the invite verification hash
   */
  [LocalStorageKey.OnboardingInvite]: {
    code: string;
    product: string;
  };

  [LocalStorageKey.LegacyOnboardingState]: 'partial' | 'full' | 'hidden';
};

export function setItem<T extends keyof Items>(key: T, value: Items[T]) {
  let newValue = value as string;

  switch (typeof value) {
    case 'object':
      newValue = JSON.stringify(value);
      break;
    case 'boolean':
    case 'number':
      newValue = String(value);
      break;
    default:
      break;
  }

  window.localStorage.setItem(key, newValue);
}

export function getItem<K extends keyof Items>(key: K) {
  const value = window.localStorage.getItem(key);

  try {
    return JSON.parse(value ?? 'null') as Items[K];
  } catch {
    return typeof value === typeof ({} as Items[K])
      ? (value as Items[K])
      : (value as string);
  }
}

export function hasItem<T extends keyof Items>(key: T) {
  return window.localStorage.getItem(key) !== null;
}

export function removeItem<T extends keyof Items>(key: T) {
  window.localStorage.removeItem(key);
}

const keys = Object.keys(LocalStorageKey).filter(
  (k) => !k.includes('OnboardingState'),
);
export function clearLocalStorage() {
  keys.forEach((key) =>
    window.localStorage.removeItem(
      LocalStorageKey[key as keyof typeof LocalStorageKey],
    ),
  );
}
