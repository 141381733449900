export function memoize<T extends (...args: any[]) => any>(fn: T): T {
  const cache: Record<string, ReturnType<T>> = {};

  return ((...args: Parameters<T>): ReturnType<T> => {
    const n = args[0] as unknown as string;

    if (n in cache) {
      return cache[n];
    }

    const result = fn(...args);
    cache[n] = result;

    return result;
  }) as T;
}
