import { Enum } from '@writercolab/utils';

export const Positions = new Enum(
  'manager',
  'vp-director',
  'business-owner',
  'c-level',
  'individual-contributor',
  'other',
);

export const positions = [
  {
    id: Positions.enum.manager,
    title: 'Manager',
  },
  {
    id: Positions.enum['vp-director'],
    title: 'VP/Director',
  },
  {
    id: Positions.enum['business-owner'],
    title: 'Business owner',
  },
  {
    id: Positions.enum['c-level'],
    title: 'C-level',
  },
  {
    id: Positions.enum['individual-contributor'],
    title: 'Individual contributor',
  },
  {
    id: Positions.enum.other,
    title: 'Other (please describe)',
  },
];
