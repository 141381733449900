import { Layout } from '../Layout/Layout';

import notFound from '~/assets/sad_woman.webp';
import { notFoundStyles } from './404.css';
import { Heading } from '@writercolab/fe.wds';
import { useRouteError } from 'react-router';
import { hardRedirect } from '~/app/utils/routerUtils';
import { Routes } from '~/constants';
import { reportRenderError } from '~/app/utils/datadog';
import { type RouteError } from '~/@types/types';

export const NotFound: React.FC = <E extends Error = Error>() => {
  const error = useRouteError() as RouteError<E>;

  if (error?.status === 404) {
    hardRedirect(Routes.Login, false);

    return null;
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
    reportRenderError({ name: 'NotFoundRenderError', error });
  }

  return (
    <Layout>
      <div className={notFoundStyles}>
        <img width={215} src={notFound} alt="Not found" />
        <Heading variant="h1">Oops something went wrong</Heading>
      </div>
    </Layout>
  );
};
