import {
  grid,
  left,
  logo as logoStyles,
  right,
  sideArt as sideArtStyle,
  product as productStyle,
  main,
  content,
} from './layout.css';
import sideArt from '~/assets/side_art.webp';
import sideArtStudio from '~/assets/side_art_studio.webp';
import type { ProductName } from '~/@types/types';
import { Logo } from '../../atoms/Logo/Logo';

function chooseArt(product?: typeof ProductName.type) {
  switch (product) {
    case 'ai-studio':
      return sideArtStudio;
    default:
      return sideArt;
  }
}

function getProductLabel(product?: typeof ProductName.type) {
  switch (product) {
    case 'ai-studio':
      return '| AI Studio';
    case 'writer-app':
      return '| Business Workspace';
    default:
      return '';
  }
}

export const Layout: React.FC<{
  children: React.ReactNode;
  product?: typeof ProductName.type;
}> = ({ children, product }) => {
  return (
    <div className={grid}>
      <div className={left}>
        <div className={main}>
          <div className={logoStyles}>
            <Logo />{' '}
            {product && (
              <div className={productStyle}>{getProductLabel(product)}</div>
            )}
          </div>

          <div className={content}>{children}</div>
        </div>
      </div>

      <div className={right}>
        <img
          width="100%"
          height="100%"
          className={sideArtStyle}
          src={chooseArt(product)}
          alt="side art"
        />
      </div>
    </div>
  );
};
