import type { Challenge } from '../utils/mfa';

export class ChallengeRequiredError extends Error {
  public readonly challenges: ReadonlyArray<Challenge>;
  public readonly name = 'ChallengeRequiredError';

  constructor(challenges: Challenge[]) {
    super('Challenge required');
    this.challenges = challenges;
  }
}
