import type { UserTraits } from '@segment/analytics-next';
import { getUserDetails } from './network';
import { fetcher } from './fetcher';
import { omit } from './omit';

export type BuiltInGAEvents =
  | 'exception'
  | 'custom-form-submit'
  | (string & {});

export function trackGAEvent(
  name: BuiltInGAEvents,
  data: {
    properties?: string;
    context?: string;
    eventCategory: 'Form Submission' | (string & {});
    userId: string;
    email: string;
    eventAction: 'Login' | 'Register' | (string & {});
    eventLabel: string;
    useCase?: string;
    department?: string;
    [key: string]: string | undefined;
  },
) {
  window.dataLayer?.push({
    event: name,
    ...data,
  });
}

let identified = false;

async function identify(userId: string, traits: Partial<UserTraits>) {
  return fetcher
    .POST('/api/analytics/identify', {
      body: {
        userId,
        traits,
      },
    })
    .then((res) => {
      if (res.response.ok) {
        identified = true;
      }
    });
}

export async function identifySegmentUser() {
  if (identified) {
    return null;
  }

  const user = await getUserDetails();

  if (user) {
    return identify(String(user?.id), omit(user, 'id'));
  }

  return null;
}

export type GAActionType = 'SAML' | 'Google' | 'Email';

export async function trackUserLogin(type: GAActionType, id = true) {
  const user = await getUserDetails();

  if (user && user.email) {
    if (id) {
      await identify(String(user.id), user);
    }

    trackGAEvent('custom-form-submit', {
      email: user.email,
      eventCategory: 'Form Submission',
      eventAction: 'Login',
      userId: String(user.id),
      eventLabel: type,
    });
  }
}
