import { useLayoutEffect, useState } from 'react';
import type { HelmetProps } from 'react-helmet';
import { Helmet as BaseHelmet } from 'react-helmet';

/**
 * Wrapper around `react-helmet` to add some default meta tags
 * and canonicals links so we don't repeat ourselves. We add sensible
 * defaults in `index.html` so we have correct descriptions when bots
 * first crawl the page. Everything after that is computed.
 */
export const Helmet: React.FC<
  Omit<HelmetProps, 'title'> & { title: string; description?: string }
> = ({ children, title, description, ...rest }) => {
  const [elems, setElems] = useState<React.ReactElement[] | null>(null);

  useLayoutEffect(() => {
    // stay SSR safe
    if (window && window.document) {
      document.querySelector('meta[name="description"]')?.remove();
      document.querySelector('link[rel="canonical"]')?.remove();
      document.querySelector('meta[property="og:title"]')?.remove();
      document.querySelector('meta[property="og:description"]')?.remove();
      document.querySelector('meta[property="og:url"]')?.remove();

      let index = 1;

      setElems((curr) => {
        if (!curr?.length) {
          const arr = [
            <link key={index++} rel="canonical" href={window.location.href} />,
            <meta
              key={index++}
              property="og:title"
              content={title ?? 'Try Writer for free'}
            />,
            <meta
              key={index++}
              property="og:url"
              content={window.location.href}
            />,
          ];

          if (description) {
            arr.push(
              <meta key={index++} name="description" content={description} />,
              <meta
                key={index++}
                property="og:description"
                content={description}
              />,
            );
          }

          return arr;
        }

        return curr;
      });
    }
  }, [description]);

  return (
    <BaseHelmet {...rest}>
      <title>{title}</title>
      {elems}
      {children}
    </BaseHelmet>
  );
};
