import * as LDClient from 'launchdarkly-js-client-sdk';
import { E_CLIENT_ID } from '@writercolab/types';

const LD_INIT_TIMEOUT = 5;

export const launchDarklyClient = async (): Promise<{
  ldClient: LDClient.LDClient;
}> => {
  const ldClient = LDClient.initialize(
    import.meta.env.VITE_LAUNCH_DARKLY,
    { kind: 'user', key: 'user-onboarding' },
    {
      application: {
        version: import.meta.env.VITE_VERSION,
        id: E_CLIENT_ID.enum.QordobaFE,
      },
    },
  );

  try {
    await ldClient.waitForInitialization(LD_INIT_TIMEOUT);
  } catch (error) {
    console.error('LaunchDarkly error', error);
  }

  return { ldClient };
};
