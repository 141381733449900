import { type paths } from '@writercolab/network';
import createClient from 'openapi-fetch';
import config from './dynamicConfig';

// normalize base url to omit the trailing slash
export const baseUrl = new URL(config.APP_ROOT).origin;
const credentials =
  import.meta.env.VITE_ENV === 'PROD' ? 'same-origin' : 'include';

const headers: Record<string, string> = {
  'X-Client': 'QordobaFE',
  'X-Client-Ver': '1.0.0',
};

export const fetcher = createClient<paths>({
  baseUrl,
  credentials,
  headers,
});
