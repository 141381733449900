import type { LoaderFunctionArgs } from 'react-router';
import type { AsyncActionWithoutResponse } from '~/@types/types';
import { consumeFlashMessage } from '~/app/utils/flash';
import { createErrorResponse, createResponse } from '~/app/utils/new/response';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const { message, status } = consumeFlashMessage();
  const data = { searchParams: url.searchParams };

  if (message && status === 'error') {
    return createErrorResponse(message, data);
  } else if (message && status === 'success') {
    return createResponse({ ...data, message });
  }

  return createResponse({ ...data, message: '' });
};

export type LoaderResult = AsyncActionWithoutResponse<typeof loader>;
