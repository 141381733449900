import { launchDarklyClient } from '~/clients/launchDarkly';
import { getItem, LocalStorageKey, setItem } from './localStorage';

export type TFeatureFlags = {
  onboardingV2: boolean;
  onboardingStarterPlan: boolean;
};

/**
 * A list of flags available to Onboarding app
 */
const availableFeatureFlags: Array<keyof TFeatureFlags> = [
  'onboardingV2',
  'onboardingStarterPlan',
];

/**
 * Initializes feature flags for the onboarding app.
 * The flags are retrieved once and stored in localStorage
 */
export const initFeatureFlags = async () => {
  const { ldClient } = await launchDarklyClient();
  const allFlags = ldClient.allFlags();

  const availableFlags = Object.entries(allFlags).reduce<TFeatureFlags>(
    (acc, [key, value]) => {
      if (availableFeatureFlags.includes(key as keyof TFeatureFlags)) {
        acc[key as keyof TFeatureFlags] = value as boolean;
      }
      return acc;
    },
    {} as TFeatureFlags,
  );

  setItem(LocalStorageKey.FeatureFlags, availableFlags);

  await ldClient.close();
};

/**
 * Retrieves a given feature flag from `localStorage`
 */
export const featureFlags = {
  get: <T extends boolean | string>(
    key: keyof TFeatureFlags,
    defaultValue: T extends boolean ? boolean : string,
  ): T extends boolean ? boolean : string => {
    const flags = getItem(LocalStorageKey.FeatureFlags) as TFeatureFlags;

    if (!flags) return defaultValue;

    return (flags[key] ?? defaultValue) as T extends boolean ? boolean : string;
  },
};
