import {
  replace as baseReplace,
  type RedirectFunction,
  redirect as baseRedirect,
} from 'react-router';
import type { Routes } from '~/constants';
import { clearLocalStorage, LocalStorageKey, setItem } from './localStorage';
import { clearSessionStorage } from './sessionStorage';

function isRelativeRoute(to: Routes) {
  return to.startsWith('/');
}

/**
 * A tiny wrapper around react-router to set the current step of the onboarding
 * and actually do the replace in the browser history. This should only be used
 * **after** the user logs in. For sign in and forgot-password flows use `replace`
 * from `react-router`.
 * @param to
 * @param init
 * @param searchParams
 * @param flush set true to call `location.replace` instead of `replace` from `react-router`.
 *  This will reset browser history.
 * @returns
 */
export const replace = (
  to: Routes,
  init?: Parameters<RedirectFunction>[1],
  searchParams?: URLSearchParams,
) => {
  const url = `${to}${searchParams ? `?${searchParams}` : ''}`;
  setItem(LocalStorageKey.OnboardingStep, to);

  if (!isRelativeRoute(to)) {
    console.error(`${to} is not a relative route`);
  }

  return baseReplace(url, init);
};

/**
 * A tiny wrapper around react-router to set the current step of the onboarding
 * and actually do the redirect in the browser history. This should only be used
 * **after** the user logs in. For sign in and forgot-password flows use `redirect`
 * from `react-router`.
 * @param to
 * @param init
 * @returns
 */
export const redirect = (
  to: Routes,
  init?: Parameters<RedirectFunction>[1],
  searchParams?: URLSearchParams,
) => {
  setItem(LocalStorageKey.OnboardingStep, to);

  return baseRedirect(`${to}${searchParams ? `?${searchParams}` : ''}`, init);
};

/**
 * Bypass `react-router` `redirect` function
 * and use `window.location.replace` instead.
 * This will automatically clear any `localStorage`
 * items specific to the onboarding flow.
 */
export function hardRedirect(
  to: string,
  doClear = true,
  searchParams?: URLSearchParams,
) {
  doClear && clearLocalStorage();
  doClear && clearSessionStorage();

  // since we completed the new onboarding flow
  // add a bypass for legacy onboarding flow if the flag is disabled
  // in prod
  setItem(LocalStorageKey.LegacyOnboardingState, 'hidden');
  window.location.replace(`${to}${searchParams ? `?${searchParams}` : ''}`);
}
