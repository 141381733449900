import { MFA_RESEND_BACKOFF } from '~/constants';
import type { ServerError } from './errors';

import { ERRORS } from './messages';
import { createVerificationHandle } from './reCAPTCHA';

import type { SessionItems } from './sessionStorage';
import {
  getSessionItem,
  hasSessionItem,
  setSessionItem,
} from './sessionStorage';

export function isMFAEnabled() {
  return import.meta.env.VITE_ENABLE_RECAPTCHA === 'true';
}

export type ChallengeMethod = 'Email' | 'Phone' | (string & {});

export type Challenge = {
  hint: string;
  method: ChallengeMethod;
  token: string;
};

export type EmailVerificationChallenge = {
  extras?: {
    challenges?: Challenge[];
  };
};

/**
 * Checks the errors to see if the user needs to verify their OTP
 * device by checking the existence of a challenge token on the response
 * error. If MFA is disabled then the function will return `false.`
 * @param errors The errors from the API response
 * @param kind The kind of action the user wants to perform
 * @param data The data that was sent to the API that needs to be written back to session storage
 */
export function isMFARequired<T extends Record<string, any>>(
  errors: ServerError[],
  kind: 'register' | 'login',
  data: T,
) {
  if (!isMFAEnabled()) {
    return null;
  }

  const invalidMfa = errors.find(
    (error) => error.key === ERRORS.InvalidMFARequired,
  );

  if (invalidMfa) {
    const challenges = (invalidMfa as EmailVerificationChallenge)?.extras
      ?.challenges;

    const challenge = challenges && challenges?.length > 0 ? challenges : null;

    if (challenge) {
      // base64 encode user input so we can use it on the verify step
      setSessionItem(
        'onboarding:account',
        window.btoa(JSON.stringify({ ...data, kind })),
      );

      // this contains one or more challenge tokens
      // but we'll only create a verification handle for the first one
      setSessionItem('onboarding:challenge', challenge);
    }

    return challenge;
  }

  return null;
}

export function getAccountDetails() {
  return JSON.parse(
    window.atob(getSessionItem('onboarding:account') as unknown as string),
  ) as SessionItems['onboarding:account'];
}

/**
 * Get the challenge token from the session storage and return a
 * reCAPTCHA verification handle. Need to pass in a custom token?
 * Create the handle yourself: {@link createVerificationHandle}
 */
export function getChallengeToken() {
  return getAllChallengeTokens()[0];
}

/**
 * Get all the challenge tokens from the session storage and return
 * the verification handles for each
 */
export function getAllChallengeTokens(): [
  ReCaptchaVerificationHandle,
  ReCaptchaVerificationHandle | undefined,
] {
  const data = getSessionItem('onboarding:challenge') as Challenge[];

  if (!data) {
    throw new Error('no challenge data');
  }

  // always assume the first token, for other methods
  // the handle needs to be created in the loader/action.
  return data.map(({ token }) => createVerificationHandle(token)) as [
    ReCaptchaVerificationHandle,
    ReCaptchaVerificationHandle | undefined,
  ];
}

/**
 * Get the list of MFA/Challenge modes. Right now we support
 * `email` and `phone` as the only modes. We'll only send
 * modes the user has enabled.
 */
export function getChallengeModes(challenges: Challenge[]) {
  return challenges.map(({ method }) => method);
}

function isInBackOffWindow(t: number) {
  const now = new Date().getTime();

  return !Number.isNaN(t) && t + MFA_RESEND_BACKOFF >= now;
}

/**
 * Indicate if the user needs to wait before sending a new MFA code.
 */
export function isInMFABackOff() {
  if (hasSessionItem('onboarding:verification-sent-at')) {
    const backoff = getSessionItem('onboarding:verification-sent-at');

    const sent = backoff as Exclude<typeof backoff, string>;

    // if we have sent a verification code in the last 'x' duration, do nothing
    return {
      primary: isInBackOffWindow(sent.primary),
      secondary: isInBackOffWindow(sent.secondary),
    };
  }

  return { primary: false, secondary: false };
}
