export const ERROR_CODES = {
  'fail.auth.login.sso.only':
    'Your organization requires that you sign in via  SAML SSO. Select continue with SAML SSO below',
  'fail.auth.mfa.challenge.invalid': '',
  'fail.auth.mfa': '',
  'fail.resource.generic': '',
  'fail.input.mfa.required': '',
  'fail.input.validator.regex': 'Enter a valid email',
  'fail.unauthorized.badCredentials':
    'This Writer account does not exist, try a different email or sign up for an account.',
  'fail.auth.saml.domain.absent':
    'Unable to sign in via SAML. Contact your IT organization to make sure you are granted access to Writer.',
  'fail.user.potential.email.old':
    'An account with this email already exists. Please try a different email.',
  'fail.user.verificationHash.invalid':
    'This reset password link has expired. Check your inbox for a more recent one or reset your password again.',
} as const;

export const ERRORS = {
  UserAlreadyRegistered: 'fail.user.potential.email.old',
  InvalidEmail: 'fail.input.validator.regex',
  InvalidPassword: 'fail.unauthorized.badCredentials',
  InvalidMFA: 'fail.auth.mfa',
  InvalidSAML: 'fail.auth.saml.domain.absent',
  InvalidSSO: 'fail.auth.login.sso.only',
  InvalidMFAChallenge: 'fail.auth.mfa.challenge.invalid',
  InvalidMFARequired: 'fail.input.mfa.required',
  OrgDiscoverabilityDisabled: 'fail.user.organization.discoverability.disabled',
} as const;

export enum ErrorMessages {
  INVALID_PASSWORD = 'Invalid password',
  YOUR_EMAIL_INVITATION_IS_NO_LONGER_VALID = 'Your email invitation is no longer valid. Please request a new invite from your organization admin.',
  ENTER_A_VALID_EMAIL = 'Enter a valid email',
  USE_A_DIFFERENT_EMAIL_TO_SIGN_UP = 'A user with this email address already exists. Please sign in to access Writer or use a different email to sign up.',
  EMAIL_ADDRESS_HAS_ALREADY_BEEN_INVITED = 'This email has already been invited to Writer. Check your inbox for an invite email. Not seeing one? Reach out to support@writer.com',
  ORG_DISCOVERY_NOT_FOUND = 'No orgs available to join. Please create a new organization.',
  YOUR_ORGANIZATION_USES_SAML_SSO = 'Your organization uses SAML SSO. Select continue with SAML SSO to sign in.',
  YOUR_EMAIL_ADDRESS_HAS_BEEN_CONFIRMED = 'Your email address has been confirmed. Sign in to use Writer.',
  YOU_EMAIL_HAS_NOT_BEEN_CONFIRMED = 'Confirmation request failed. Your email is already confirmed or the confirmation code is wrong!',
  YOUR_EMAIL_HASH_IS_NO_LONGER_VALID = 'Your email hash is no longer valid.',
  PASSWORDS_DONT_MATCH = 'Passwords don’t match',
  SOMETHING_WENT_WRONG_TRY_AGAIN_LATER = 'Something went wrong. Try again later.',
  SSO_TOKEN_INVALID = 'There was a problem during SAML sign-on. Please try again.',
  YOUR_ACCOUNT_WAS_SUCCESSFULLY_DELETED = 'Your organization was successfully deleted.',
  EMAIL_EXISTS = 'A user with this email address already exists. Please sign in to access Writer or use a different email to sign up.',
  RECAPTCHA_VERIFICATION_FAILED = 'Failed to complete Multi-Factor Authentication (MFA) challenge',
  RECAPTCHA_VERIFICATION_FAILED_SUSPICIOUS_ACTIVITY = 'Unable to create your account due to high risk of suspicious activity. Reach out to support@writer.com for help.',
  PLEASE_TRY_AGAIN_LATER = 'Something went wrong. Please try again later.',
  CONTACT_YOUR_ORGANIZATION_ADMIN_FOR_AN_INVITATION = 'contact your organization admin for an invitation to join writer',
  INCORRECT_CODE = 'Incorrect code',
  INCORRECT_CODE_LIMIT_REACHED = "You've reached the limit of incorrect attempts. Try resending the code.",
  INCORRECT_USERNAME_OR_PASSWORD = 'Incorrect username or password',
}

export enum GOOGLE_MFA_ERROR_MESSAGE {
  FAILED_TO_CHALLENGE_ACCOUNT = 'Failed to challenge account',
  INCORRECT_CODE = 'Incorrect code',
  INCORRECT_CODE_EXPIRED = "You've reached the limit of incorrect attempts. Try resending the code.",
  FAIL_TO_RESEND_CODE = 'Failed to resend code',
  NO_VERIFICATION_HANDLE = 'No verification handle',
}

export const SAML_SSO_ERROR_MESSAGE = `Error: SAML response parsing error. Please reach out to your IT admin to resolve.`;
