type Status = 'error' | 'success';

let lastMessage: string = '';
let lastStatus: Status = 'error';

export function flash(message: string, status: Status = 'error') {
  lastMessage = message;
  lastStatus = status;
}

export function consumeFlashMessage() {
  const message = lastMessage;
  lastMessage = '';

  return { message, status: lastStatus };
}
